import {
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { graphql } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import Masonry from "react-masonry-css";
import HeroSection from "../components/HeroSection/HeroSection";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { cleanImgBase, Images } from "../utils/findImage";

interface ProjectsPageProps {
  location: Location;
  data: {
    bgImage: {
      gatsbyImageData: IGatsbyImageData;
    };
    projectImages: Images;
  };
}

const projectCategories = ["all", "kitchen-cabinets", "interior", "exterior"];
const projectCategoriesTitles = [
  "All",
  "Kitchen cabinets",
  "Interiors",
  "Exteriors",
];

const ProjectsPage: React.FC<ProjectsPageProps> = ({ location, data }) => {
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const allImages = data.projectImages;

  const [projectsToDisplay, setProjectsToDisplay] = useState<Images>(allImages);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleProjectsToDisplay = () => {
    if (value === 0) {
      setProjectsToDisplay(allImages);
    } else {
      const imagesToDisplay: Images = { nodes: [] };

      imagesToDisplay.nodes = allImages.nodes.filter((img) =>
        img.childImageSharp.original.src.includes(projectCategories[value])
      );

      setProjectsToDisplay(imagesToDisplay);
    }
  };

  useEffect(() => {
    handleProjectsToDisplay();
  }, [value]);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    _: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setValue(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Layout>
      <SEO
        title="Projects"
        description="View a selection of our completed kitchen cabinet refinishing, home interior &amp; exterior, and commercial projects. Contact us for a free estimate."
        slug={location.pathname}
      />

      <HeroSection
        bgImage={data.bgImage.gatsbyImageData}
        small
        heading="Projects"
      />

      <section>
        <div>
          <div>
            <div className="w-full md:mb-8 mb-4">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
                className="hidden md:block"
              >
                {projectCategoriesTitles.map((item, index) => (
                  <Tab key={`${item}-tab-${index}`} label={item} />
                ))}
              </Tabs>

              <div className="md:hidden">
                <List component="nav" aria-label="Project categories">
                  <ListItem
                    button
                    aria-haspopup="true"
                    aria-controls="lock-menu"
                    aria-label="Select Project category"
                    onClick={handleClickListItem}
                    className="capitalize"
                  >
                    <ListItemText
                      primary="Project Category"
                      secondary={projectCategoriesTitles[value]}
                      className="capitalize"
                    />
                  </ListItem>
                  <Menu
                    id="lock-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {projectCategoriesTitles.map((item, index) => (
                      <MenuItem
                        key={`${item}-select-${index}`}
                        value={index}
                        selected={index === value}
                        onClick={(event) => handleMenuItemClick(event, index)}
                        style={{ textTransform: "capitalize" }}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Menu>
                </List>
              </div>
            </div>
            <div>
              <Masonry
                breakpointCols={{ default: 4, 640: 1, 1024: 2 }}
                className="col-span-full flex flex-wrap"
                columnClassName="px-0.5 bg-clip-padding"
              >
                {projectsToDisplay.nodes.map((img, index) => (
                  <div key={img.relativePath} className="mb-1">
                    <GatsbyImage
                      image={img.childImageSharp.gatsbyImageData}
                      alt={cleanImgBase(img.base)}
                    />
                  </div>
                ))}
              </Masonry>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ProjectsPage;

export const pageQuery = graphql`
  query {
    bgImage: imageSharp(original: { src: { regex: "/exterior-painting-8/" } }) {
      id
      gatsbyImageData(layout: FULL_WIDTH)
    }
    projectImages: allFile(
      filter: { relativeDirectory: { eq: "projects" } }
      sort: { fields: base, order: DESC }
    ) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 90)
          original {
            src
          }
        }
        base
      }
    }
  }
`;
